/*NAV BAR*/

#navbarCollapse {
  justify-content: space-between;
  padding-right: 20px;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.bg-newcolor {
  color:#000000;
  background-color: #fe6e60;
  }

.navbar .img-logo{
  width: 50px;
  height: 50px;
} 

.navbar .Logo{
  font-size: 28px;
  color: white;
  vertical-align: middle;
} 