

* {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
}

body, html {
    height: 100%;
}

html {
    line-height: 1.15;
}

#Register {

    padding-top: 100px;
    padding-bottom: 100px;
}

.wrap-login100 {
    width: 450px;
    background: rgb(255, 255, 255);
    border-radius: 3px;
    overflow: hidden;
    margin: auto;
}

.p-r-50 {
    padding-right: 50px;
}

.p-l-50 {
    padding-left: 50px;
}

.p-b-30 {
    padding-bottom: 30px;
}

.p-t-77 {
    padding-top: 77px;
}

.validate-input {
    position: relative;
}

.wrap-input100 {
    width: 100%;
    z-index: 1;
}
.m-b-16 {
    margin-bottom: 16px;
}

div {
    display: block;
}

.input100 {
    font-size: 18px;
    line-height: 1.2;
    color: #686868;
    display: block;
    width: 100%;
    background: #e6e6e6;
    height: 62px;
    border-radius: 3px;
    padding: 0 30px 0 65px;
}

.m-b-10 {
    margin-bottom: 10px;
}

@media (max-width: 480px) {
    .wrap-login100 {
        padding-left: 15px;
        padding-right: 15px;
    }
}

