@import url("https://use.typekit.net/ory5afa.css");
@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import url('https://fonts.googleapis.com/css2?family=Roboto');
@font-face {
  font-family: 'segoe-ui';
  src: local('Segoe UI', url('./assets/fonts/segoe-ui/Segoe\ UI.woff'));
}

* {
  box-sizing: border-box;
}

/*GENERAL*/
html, body {
  width: 100%;
  height: 100%;
}

#root {
  ms-flex-direction: column !important;
  flex-direction: column !important;
  display: -ms-flexbox !important;
  display: flex !important;
  font-family: 'Roboto';

}

.icuida-blue {
  background-color: #bdf0ff !important;
}

a {
  color: black;

}

a:hover {
  text-decoration: none;
}

button:focus {
  outline: 0!important;
  box-shadow: none!important;
}

.demo-editor {
  height: 200px !important;
  border: 1px solid #F1F1F1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
}

.fit-content-w {
  width: fit-content !important;
}

.text-utils {
  display: flex;
  justify-content: space-between;
}

.error-box {
  background-color: #f5bec3;
  color: initial !important;

}

.error-message {
  font-size: 14px;
  font-weight: 600;
  color: #dc3545;
}

.success-message {
  font-size: 14px;
  font-weight: 600;
  background-color: #a0f9a4;
  color: white;
  border: 2px solid #0fdc70;
}

.img-logo {
  height: 75px;
  width: 75px;
}

.img-thumbnail {
  padding: 0 !important;
}

.facebook-button {
  background: #2676E1;
  width: 100%;
  border-radius: 50px;
  color: white;
  margin-bottom: 10px;
  height: 50px;
}

.facebook-button i {
  font-size: 20px;
}

.google-button {
  background: #F95B5B;
  width: 100%;
  border-radius: 50px;
  color: white;
  margin-bottom: 10px;
  height: 50px;
}

.facebook-button:hover, .google-button:hover {
  background: #E2E8F0;
}

.w100 {
  width: 100%;
}

.article-card a span{
  color:#4ea4dc;
  text-decoration: underline;
}
/*CARD CSS*/

.article-card ul {
  padding: 15px;
}

.article-card .LinesEllipsis-ellipsis {
  color:#4ea4dc;
  text-decoration: underline;
}